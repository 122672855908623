<ng-template [ngIf]="previousUrl !== '/thrive-step-form/store-setting'" [ngIfElse]="loader">
    <mat-drawer-container [hasBackdrop]="isTouchDevice">
        <mat-drawer #drawer id="drawer" [mode]="isTouchDevice ? 'over' : 'side'" [disableClose]="isTouchDevice ? false : true" [opened]="!isTouchDevice" clickOutside (clickOutside)="selectedHoverMenu = ''">
            <img [src]="isExpanded ? 'assets/img/logo.svg' : 'assets/img/sidebar/T_logo.svg'" [ngClass]="isExpanded ? 'logo-expanded' : 'logo-collapsed'" alt="Thrive Logo" routerLink="/overview" />

            <div *ngIf="isTouchDevice" class="mobile-sidenav-extras">
                <outlet-selector></outlet-selector>
                <wallet-header-info (toggleMenuEvent)="drawer.close()"></wallet-header-info>
            </div>

            <mat-nav-list class="menu">
                <aside>
                    <div *ngFor="let item of menuItems" class="menu-container" [matTooltipDisabled]="isTouchDevice ? true : isExpanded && showTooltip" [matTooltip]="item.name" matTooltipPosition="left" matTooltipClass="thrive-tooltip">
                        <a *ngIf="item.display && !item.sub_menu" mat-list-item class="sidenav-link" [style.width]="isExpanded ? (isTouchDevice ? '100%' : '205px') : '56px'" routerLinkActive="selected-option" (click)="navigateTo(item.name); isTouchDevice ? drawer.close() : null;" [routerLink]="[item.link]">
                            <mat-icon [ngClass]="currentUrl?.includes(item.link) ? 'material-icons' : 'material-icons-outlined'" [innerHTML]="item.icon"></mat-icon>
                            <p class="title-regular" *ngIf="isExpanded" mat-line>
                                {{ item.name }}
                                <sup *ngIf="item.beta">Beta</sup>
                            </p>
                        </a>
                        <a *ngIf="item.display && item.sub_menu" [style.width]="isExpanded ? (isTouchDevice ? '100%' : '205px') : '56px'" [class.selected-option]="subMenuSelectedLink(item.name)" [matMenuTriggerFor]="sideMenu" mat-list-item class="sidenav-link" (click)="navigateTo(item.name)">
                            <app-icon *ngIf="item.name != 'Logout'" class="down-arrow-icon" [ngClass]="{ open: item.show_drop_down }" [iconName]="'arrow-right-black'" [iconStyles]="{ fill: '#000', width: '20px', height: '20px' }"></app-icon>
                            <mat-icon [ngClass]="subMenuSelectedLink(item.name) ? 'material-icons' : 'material-icons-outlined'" [innerHTML]="item.icon"></mat-icon>
                            <p class="title-regular" *ngIf="isExpanded" mat-line>
                                {{ item.name }}
                                <sup *ngIf="item.beta">Beta</sup>
                            </p>
                        </a>
                        <!-- sub menu for main menu -->
                        <div *ngIf="item.display && item.show_drop_down">
                            <div *ngFor="let sub_menu of item.sub_menu">
                                <a *ngIf="sub_menu.display" [ngClass]="{'selected-option': currentUrl?.includes(sub_menu.link)}" class="sub-link" [href]="sub_menu.link" [routerLink]="[sub_menu.link]" (click)="drawer.close(); closeExpandedSidebar()">
                                    {{ sub_menu.name }}
                                    <sup *ngIf="sub_menu.beta">Beta</sup>
                                </a>
                            </div>
                        </div>
                        <div class="hover-menu" *ngIf="selectedHoverMenu === item.name" [style.left]="isExpanded ? '232px' : '83px'">
                            <mat-list>
                                <ng-container *ngFor="let sub_menu of item.sub_menu">
                                    <mat-list-item [ngClass]="{'selected-option': currentUrl?.includes(sub_menu.link)}" class="hover-submenu" (click)="selectedHoverMenu = ''" [routerLink]="[sub_menu.link]" *ngIf="sub_menu.display">
                                        {{ sub_menu.name }}
                                        <sup *ngIf="sub_menu.beta">Beta</sup>
                                    </mat-list-item>
                                </ng-container>
                            </mat-list>
                        </div>
                    </div>
                </aside>
                <a mat-list-item class="sidenav-link" [style.width]="isExpanded ? (isTouchDevice ? '100%' : '205px') : '56px'" (click)="toggleMenu()" *ngIf="!isTouchDevice" [matTooltipDisabled]="isTouchDevice ? true : isExpanded && showTooltip" [matTooltip]="isExpanded ? 'Collapse' : 'Expand'" matTooltipPosition="left" matTooltipClass="thrive-tooltip">
                    <mat-icon class='material-icons'>keyboard_tab</mat-icon>
                    <p class="title-regular" *ngIf="isExpanded" mat-line>Collapse</p>
                </a>

                <ng-container *ngIf="isTouchDevice">
                    <!-- <a mat-list-item class="sidenav-link sidenav-border-top" (click)="openWhatIsNew()">
          <img mat-list-icon src="assets/img/sidebar/whatsnew.svg" />
          <p class="title-regular" mat-line>What’s New?</p>
        </a> -->
                    <a mat-list-item class="sidenav-link" href="https://thrivenow.zohodesk.com/portal/en/home" target="_blank">
                        <img mat-list-icon src="assets/img/sidebar/knowledgebase.svg" />
                        <p class="title-regular" mat-line>Knowledge Base</p>
                    </a>
                    <a mat-list-item *ngIf="merchant?.is_loyalty_model && merchant?.role != 'ChainOwner'" class="sidenav-link" (click)="topNavigation('marketing-material'); drawer.close()">
                        <img mat-list-icon src="assets/img/sidebar/marketingmaterial.svg" />
                        <p class="title-regular" mat-line>Marketing Material</p>
                    </a>
                    <a href="mailTo: support&#64;hashtagloyalty.com" mat-list-item class="sidenav-link" (click)="drawer.close()">
                        <mat-icon>mail_outline</mat-icon>
                        <p class="title-regular" mat-line>Contact Support</p>
                    </a>
                </ng-container>
            </mat-nav-list>
        </mat-drawer>

        <mat-drawer-content>
            <app-navigation-header (toggleMenuEvent)="drawer.toggle()" [isExpanded]="isExpanded"></app-navigation-header>
            <div class="basic-container" [style.padding-top]="(show_banner.includes(currentUrl) && !merchant?.staff_credentials_setup) ? '200px' : '105px'">
                <!-- Template Notification -->
                <toaster-container></toaster-container>

                <!-- Banner to manually trigger free trial -->
                <div class="freeTrial-banner" *ngIf="merchant?.show_free_trial_toggle">
                    <img src="assets/img/free-trial.png" alt="Free Trial">
                    <div>
                        <p>Activate the <b>PRO</b> plan and all the pro features <b>FREE for 30 days. Complete checklist now.</b></p>
                        <p>To know what all is included in PRO plan <a (click)="openDetailPopup()">Click Here</a></p>
                    </div>
                    <div class="toggle-container">
                        <span>Activate FREE TRIAL from here</span>
                        <img src="assets/img/free-trial-arrow.png" alt="Arrow">
                        <mat-slide-toggle color="primary" (change)="enableFreeTrial($event.checked)"></mat-slide-toggle>
                    </div>
                </div>

                <div class="banner" *ngIf="show_banner.includes(currentUrl) && !merchant?.staff_credentials_setup">
                    <div class="banner__pos">
                        <img class="banner__img" src="../../../assets/img/banner.svg" />
                        <div class="banner__center">
                            <div class="banner__content m-r-20">Ready to start receiving orders from your customers? Set up your order dashboard.</div>
                            <a [href]="merchant?.staff_login_url" target="_blank" class="btn-thrive_primary">Continue setup</a>
                        </div>
                    </div>
                </div>

                <!-- TMS pricing count down timer-->
                <!-- <status-tms></status-tms> -->

                <app-loader></app-loader>
                <router-outlet></router-outlet>

            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</ng-template>
<ng-template #loader>
    <home-loader [message]="'Please wait while we are setting up your dashboard...'"></home-loader>
</ng-template>
